import React from "react"
import {graphql} from "gatsby"

import urlParam from '../lib/url-param'

import Layout from '../layouts/default'

import Header from '../components/header'
import SearchResults from '../components/search-results'

import TextHeader from '../content-elements/text-header'
import Search from '../content-elements/search'

export default ({data, pageContext}) => {

  const templateStrings = data.allDatoCmsTemplateTexte.nodes[0];

  const searchQueryParam = urlParam('s');
  const query = searchQueryParam ? searchQueryParam.value : '';

  const localeLinks = pageContext.localeLinks.map(l => {
    l.url += '?s=' + query;
    return l;
  })

  return (
      <Layout
          locale={pageContext.locale}
          localeLinks={localeLinks}
          title={templateStrings.searchresultsTitleLabel}
          robots="noindex,follow"
      >
        {{
          header: (
              <Header
                  locale={pageContext.locale}
                  localeLinks={localeLinks}
                  transparent={true}
              >
                <TextHeader
                    center={true}
                    title={templateStrings.searchresultsTitleLabel}
                    headline={templateStrings.searchresultsResultTxt.replace('%query%', query)}
                    headlineType="h1"
                />
              </Header>
          ),
          content: (
              <>
                <Search locale={pageContext.locale} />

                <SearchResults
                    locale={pageContext.locale}
                    query={query}
                    loadingText={templateStrings.searchresultsLoadingTxt}
                    errorText={templateStrings.searchresultsErrorTxt}
                    emptyText={templateStrings.searchresultsEmptyTxt}
                />
              </>
          ),
        }}
      </Layout>
  )
}

export const query = graphql`
    query($locale: String!) {
        allDatoCmsTemplateTexte(filter: {locale: {eq: $locale}}) {
            nodes {
                searchresultsTitleLabel
                searchresultsResultTxt
                searchresultsLoadingTxt
                searchresultsErrorTxt
                searchresultsEmptyTxt
            }
        }
    }
`
