import React from "react"
import {graphql, StaticQuery} from "gatsby"

import styles from "./../component.module.less"

class Pager extends React.Component {

  locale;
  pages = [];

  onChangeHandler = () => {};

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';

    this.pages = Array.from({ length: (props.count || 0) });

    if (props.onChange) {
      this.onChangeHandler = props.onChange;
    }

    this.state = {
      current: props.current || 1
    };
  }

  onPageChange(e, page) {
    e.preventDefault();

    this.setState(() => ({
      current: page
    }));

    this.onChangeHandler(page);
  }

  render() {
    if (this.pages.length <= 1) {
      return null;
    }

    return (
        <StaticQuery
            query={graphql`
              query {
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          pagerPrevLabel
                          pagerNextLabel
                      }
                  }
              }
          `}
          render={data => {
            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === this.locale);

            return (
                <ol className={`${styles.pager} ${this.props.className || ''}`}>

                  {/* prev */}
                  <li className={styles.prev}>
                    {this.state.current <= 1 &&
                        <span>{templateStrings.pagerPrevLabel}</span>
                    }
                    {this.state.current > 1 &&
                        <button onClick={e => {this.onPageChange(e, this.state.current - 1)}}>
                          {templateStrings.pagerPrevLabel}
                        </button>
                    }
                  </li>

                  {/* pages */}
                  {this.pages.map((_, i) => (
                      <li key={'pg' + i} className={this.state.current === i + 1 ? styles.cur : ''}>
                        <button onClick={e => {this.onPageChange(e, i + 1)}}>{i + 1}</button>
                      </li>
                  ))}

                  {/* next */}
                  <li className={styles.next}>
                    {this.state.current + 1 > this.pages.length &&
                        <span>{templateStrings.pagerNextLabel}</span>
                    }
                    {this.state.current < this.pages.length &&
                        <button onClick={e => {this.onPageChange(e, this.state.current + 1)}}>
                          {templateStrings.pagerNextLabel}
                        </button>
                    }
                  </li>
                </ol>
            );
          }}
        />
    );
  }
}

export default Pager;
