import React from "react"

import SearchService from '../../lib/service/search'

import ContentContainer from '../../components/content-container'
import Pager from "../../components/pager/by-event"

import styles from './component.module.less'

const itemsPerPage = 6;

class SearchResults extends React.Component {

  locale;
  searchService;
  query;
  containerRef;

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';

    this.containerRef = React.createRef();

    this.query = props.query || '';

    this.searchService = new SearchService(process.env.GATSBY_API_SEARCH_URL, this.locale);

    this.state = {
      error: false,
      isSearching: true,
      results: [],
      pager: {
        current: 1,
        count: 1
      }
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    this.searchService.find(this.query)
        .then(results => {
          // no results
          if (results.meta.total_count === 0) {
            this.setState(() => ({
              isSearching: false
            }));
            return results;
          }

          // map results
          const searchResults = results.data.map(r => {
            return {
              id: r.id,
              url: r.attributes.url,
              title: r.attributes.title,
              preview: (() => {
                if (!r.attributes.highlight) {
                  return null;
                }

                if (r.attributes.highlight.body) {
                  return r.attributes.highlight.body[r.attributes.highlight.body.length - 1]
                      .replace(/(\[h])|(\[\/h])/g, '')
                      .trim();
                }

                if (r.attributes.highlight.title) {
                  return r.attributes.highlight.title[r.attributes.highlight.title.length - 1]
                      .replace(/(\[h])|(\[\/h])/g, '')
                      .trim();
                }

                return null;
              })()
            };
          });

          this.setState(() => ({
            isSearching: false,
            results: searchResults,
            pager: {
              current: 1,
              count: Math.round(searchResults.length / itemsPerPage)
            }
          }));
        })
        .catch(e => {
          this.setState({
            error: true,
          });
        });
  }

  onPageChange(page) {
    if (!this.containerRef.current) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, this.containerRef.current.offsetTop - 260);
    }

    let state = this.state;
    state.pager.current = page;
    this.setState(() => state);
  }

  render() {
    const results = this.state.results.slice((this.state.pager.current - 1) * itemsPerPage, ((this.state.pager.current - 1) * itemsPerPage) + itemsPerPage);
    return (
        <ContentContainer tag="section" className={styles.searchResults} reactRef={this.containerRef}>
          <div className={styles.inner}>
            {this.state.isSearching && <span>{this.props.loadingText}</span>}

            {this.state.error && <span>{this.props.errorText}</span>}

            {!this.state.isSearching &&
              <>
                {this.state.results.length === 0 && <span>{this.props.emptyText}</span>}

                {this.state.results.length > 0 &&
                  <>
                    <ol className={styles.list}>
                      {results.map(result => (
                          <li key={result.id}>
                            <a href={result.url}>
                              <span className={styles.title}>{result.title}</span>
                              <span className={styles.url}>{result.url}</span>
                              {result.preview &&
                                <span>{result.preview}...</span>
                              }
                            </a>
                          </li>
                      ))}
                    </ol>

                    <Pager
                        locale={this.locale}
                        count={this.state.pager.count}
                        current={this.state.pager.current}
                        onChange={this.onPageChange}
                    />
                  </>
                }
              </>
            }
          </div>
        </ContentContainer>
    );
  }
}

export default SearchResults
